<!--
 * @Author: Blues
 * @Description: 用户本人的信息
-->
<template>
    <div>
        <div class="card-container">
            <div class="card-heard">申请人信息</div>
            <el-form ref="userForm" :model="userForm" :rules="rules" label-width="100px" :label-position="labelPosition" class="demo-ruleForm">
                <div class="one-input">
                    <el-form-item label="主申姓名：" prop="username">
                        <div class="input-width">
                            <el-input v-model.trim="userForm.username" clearable maxlength="20" placeholder="与身份证一致" />
                        </div>
                    </el-form-item>
                </div>
                <div class="one-input doubles">
                    <el-form-item label="港澳通行证号码：" label-width="140px" prop="passNumber">
                        <div style="display:flex;">
                            <div class="input-width150">
                                <el-input v-model.trim="userForm.passNumber" onkeyup="this.value=this.value.replace(/[, ]/g,'')" :disabled="disabled" clearable maxlength="20" placeholder="外国籍请填写护照号码" />
                            </div>
                            <el-checkbox v-model="checked" style="position:relative;top:-2px;left:12px;">暂未办理</el-checkbox>
                        </div>
                    </el-form-item>
                </div>
                <div class="one-input">
                    <el-form-item label="曾用名：" prop="usedName">
                        <div class="input-width">
                            <el-input v-model.trim="userForm.usedName" clearable maxlength="20" placeholder="如有其他名字，请填写" />
                        </div>
                    </el-form-item>
                </div>
                <div class="one-input doubles">
                    <el-form-item label="中国身份证号码：" label-width="140px" prop="identityCard">
                        <div class="input-width">
                            <el-input v-model.trim="userForm.identityCard" clearable maxlength="18" placeholder="请输入" />
                        </div>
                    </el-form-item>
                </div>
                <div class="one-input">
                    <el-form-item label="性别：" prop="sex">
                        <el-select v-model="userForm.sex" style="width:340px;margin-left:0;" placeholder="请选择" clearable>
                            <el-option label="男" :value="1" />
                            <el-option label="女" :value="2" />
                        </el-select>
                    </el-form-item>
                </div>
                <div class="one-input doubles">
                    <el-form-item label="手机号：" label-width="140px" prop="mobile">
                        <div class="input-width">
                            <el-input v-model.trim="userForm.mobile" onkeyup="value=value.replace(/[^\d]/g,'')" clearable maxlength="11" placeholder="请输入" />
                        </div>
                    </el-form-item>
                </div>
                <div class="one-input">
                    <el-form-item label="出生日期：" prop="birthday">
                        <el-date-picker v-model="userForm.birthday" value-format="yyyy-MM-dd" type="date" style="width:340px;" :picker-options="pickerOptions" placeholder="请选择" />
                    </el-form-item>
                </div>
                <div class="one-input doubles">
                    <el-form-item label="邮箱：" label-width="140px" prop="email">
                        <div class="input-width">
                            <el-input v-model.trim="userForm.email" clearable maxlength="50" placeholder="请输入" onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g,'')" />
                        </div>
                    </el-form-item>
                </div>
                <div class="one-input">
                    <el-form-item label="出生城市：" prop="birthPlaceAboard">
                        <el-radio-group v-model="userForm.birthPlaceAboard" @change="changeBirthPalce($event,userForm)">
                            <el-radio :label="0">国内</el-radio>
                            <el-radio :label="1">国外</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <span v-if="userForm.birthPlaceAboard === 0" style="position:relative;top:-52px;left:250px;">
                        <CitySelect v-model="userForm.birthPlace.area" style="width: 190px;" />
                    </span>
                    <span v-if="userForm.birthPlaceAboard === 1" style="position:relative;top:-52px;left:250px;">
                        <el-input v-model.trim="userForm.birthPlace.foreign" clearable style="width:190px;" maxlength="20" placeholder="请输入" />
                    </span>
                </div>
                <div class="one-input doubles" style="position:relative;top:-50px;">
                    <el-form-item label="现定居国家：" label-width="140px" prop="liveCountry">
                        <div class="input-width">
                            <el-input v-model.trim="userForm.liveCountry" clearable maxlength="20" placeholder="请输入" />
                        </div>
                    </el-form-item>
                </div>
                <div style="position: relative;top: -48px;">
                    <div class="one-input" style="position:relative;top:-50px;">
                        <el-form-item label="婚姻状况：" prop="married">
                            <el-select v-model="userForm.married" style="width:340px;margin-left:0;" placeholder="请选择" clearable>
                                <el-option label="已婚" value="1" />
                                <el-option label="未婚" value="2" />
                                <el-option label="离婚" value="3" />
                                <el-option label="分居" value="4" />
                                <el-option label="丧偶" value="5" />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="one-input" style="margin-left:60px;">
                        <el-form-item label="现居住地址：" label-width="140px" prop="addressAboard">
                            <el-radio-group v-model="userForm.addressAboard" @change="changeAddress">
                                <el-radio :label="0">国内</el-radio>
                                <el-radio :label="1">国外</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <span v-if="userForm.addressAboard == 0" style="position:relative;top:-52px;left:288px;">
                            <CitySelect v-model="userForm.address.area" style="width: 190px;" />
                        </span>
                        <span v-if="userForm.addressAboard == 1" style="position:relative;top:-52px;left:288px;">
                            <el-input v-model.trim="userForm.address.foreign" style="width:190px;" maxlength="100" placeholder="请输入" />
                        </span>
                    </div>
                </div>
                <div style="position:relative;top:-95px;">
                    <div class="one-input" style="position: relative;top:-15px;">
                        <el-form-item label="国籍：" prop="nationality">
                            <div class="input-width">
                                <el-select v-model="userForm.nationality" style="width:340px;" clearable filterable remote reserve-keyword placeholder="请输入" :remote-method="queryCountryAsync" :loading="opctionLoading">
                                    <el-option v-for="item in countryOption" :key="item.name_en" :label="item.name_cn + '-' + item.name_en" :value="item.name_cn + '-' + item.name_en" />
                                </el-select>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="one-input doubles">
                        <el-form-item label="详细地址：" label-width="140px" prop="address.details">
                            <div style="width:340px;">
                                <el-input v-model.trim="userForm.address.details" type="textarea" placeholder="目前居住地，具体到门牌号" />
                            </div>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
        </div>
        <div class="operations-group">
            <el-button v-if="(user.baseSchedule - 1) < 1" class="person-btn" :loading="isSave" @click="saveForm('save')">保存</el-button>
            <el-button class="person-btn btn-blue" @click="saveForm('userForm')">下一步</el-button>
        </div>
    </div>
</template>

<script lang="javascript">
import CitySelect from "@/components/CitySelect/CitySelect";
import { saveInformation, querySearchCountry } from "@/api/workfow.js";
import { mapActions, mapState } from "vuex";
import { setTimeout } from "timers";
export default {
    name: "User",
    components: {
        CitySelect,
    },
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
            labelPosition: "left",
            checked: true,
            disabled: false,
            option: "save",
            userForm: {
                id: "",
                orderId: Number(this.$route.params.id),
                username: "", // 主申请人姓名
                usedName: "", // 曾用名
                isHandled: 0, // 暂未办理
                passNumber: "", // 港澳通行证
                identityCard: "", // 身份证或护照
                sex: "", // 性别:0为未知,1为男,2为女
                mobile: "", // 手机号
                nationality: "", // 国籍
                email: "", // 邮箱
                married: "", // 婚姻状态 1=已婚,2=未婚,3=离婚,4=分居 5-丧偶
                birthday: null, // 生日
                liveCountry: "", // 现定居国家
                address: {
                    // 现居住地址
                    area: [],
                    foreign: "",
                    details: "",
                },
                birthPlace: {
                    // 出生城市
                    area: [],
                    foreign: "",
                },
                birthPlaceAboard: 0, // 出生城市  0-国内 1-国外
                addressAboard: 0, // 现居住地址 0-国内 1-国外
            },
            rules: {
                username: [
                    {
                        required: true,
                        message: "请输入主申姓名",
                        trigger: "change",
                    },
                ],
                sex: [
                    {
                        required: true,
                        message: "请选择性别",
                        trigger: "change",
                    },
                ],
                birthday: [
                    {
                        required: true,
                        message: "请选择出生日期",
                        trigger: "change",
                    },
                ],
                birthPlace: [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                nationality: [
                    {
                        required: true,
                        message: "请输入国籍",
                        trigger: "change",
                    },
                ],
                mobile: [
                    {
                        required: true,
                        message: "请输入手机号码",
                        trigger: "change",
                    },
                ],
                email: [
                    {
                        required: true,
                        validator: this.$rules.validate.email,
                        trigger: "blur",
                    },
                ],
                liveCountry: [
                    {
                        required: true,
                        message: "请输入现定居国家",
                        trigger: "change",
                    },
                ],
                birthPlaceAboard: [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                addressAboard: [
                    {
                        required: true,
                        message: "请选择",
                        trigger: "change",
                    },
                ],
                married: [
                    {
                        required: true,
                        message: "请选择婚姻状况",
                        trigger: "change",
                    },
                ],
                "address.details": [
                    {
                        required: true,
                        message: "请输入详细地址",
                        trigger: "change",
                    },
                ],
            },
            isSave: false, //防抖loading
            opctionLoading: false,
            countryOption: [], // 国籍列表
        };
    },
    computed: {
        ...mapState(["user"])
    },
    watch: {
        checked(val) {
            this.userForm.isHandled = !val ? 0 : 1;
            this.disabled = val ? true : false;
        },
    },
    created() {
        this.dataEcho();
    },
    methods: {
        ...mapActions(["getInfoData"]),
        // 数据回显
        dataEcho() {
            this.getInfoData(this.$route.params.id).then((res) => {
                if (res.code == 200) {
                    this.userForm = res.data.information
                        ? res.data.information
                        : this.userForm;
                    this.checked = this.userForm.isHandled == 1 ? true : false;
                    this.disabled = this.userForm.isHandled == 1 ? true : false;
                    this.userForm.sex =
                        this.userForm.sex === 0 ? "" : this.userForm.sex;
                }
            });
        },
        // 获取国籍
        queryCountryAsync(keyword) {
            this.opctionLoading = true;
            querySearchCountry({
                keyword,
            }).then((res) => {
                this.opctionLoading = false;
                this.countryOption = res.data;
            });
        },
        addApplyer(v) {
            if (v == 2) {
                if (this.userForm.birthPlaceAboard == 0) {
                    if (
                        this.userForm.birthPlace.area.length == 0 ||
                        !this.userForm.birthPlace.area
                    ) {
                        this.$message.error("请选择出生城市");
                        return false;
                    }
                }
                if (this.userForm.birthPlaceAboard == 1) {
                    if (
                        this.userForm.birthPlace.foreign == "" ||
                        !this.userForm.birthPlace.foreign
                    ) {
                        this.$message.error("请输入出生城市");
                        return false;
                    }
                }
                if (this.userForm.addressAboard == 0) {
                    if (
                        this.userForm.address.area.length == 0 ||
                        !this.userForm.address.area
                    ) {
                        this.$message.error("请选择现居住地址");
                        return false;
                    }
                }
                if (
                    !/^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/.test(
                        this.userForm.email
                    ) &&
                    this.userForm.email !== "" &&
                    this.userForm.email !== undefined
                ) {
                    this.$message.error("请输入正确的邮箱");
                    return false;
                }
                if (this.userForm.addressAboard == 1) {
                    if (
                        this.userForm.address.foreign == "" ||
                        !this.userForm.address.foreign
                    ) {
                        this.$message.error("请输入现居住地址");
                        return false;
                    }
                }
                if (
                    this.userForm.passNumber == undefined &&
                    this.userForm.identityCard == undefined
                ) {
                    this.$message.error("请输入港澳通行证或身份证号码");
                    return false;
                }
                if (
                    (this.userForm.passNumber == "" &&
                        !this.userForm.identityCard) ||
                    (!this.userForm.passNumber &&
                        this.userForm.identityCard == "")
                ) {
                    this.$message.error("请输入港澳通行证或身份证号码");
                    return false;
                }
                // 校验身份证
                if (
                    this.userForm.identityCard !== "" &&
                    this.userForm.identityCard !== undefined
                ) {
                    if (
                        !/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
                            this.userForm.identityCard
                        )
                    ) {
                        this.$message.error("请输入正确的身份证号码");
                        return false;
                    }
                }

                // 校验港澳通行证 2021.5.26 文龙说取消校验 最长20个字
                // if (
                //     this.userForm.passNumber !== "" &&
                //     this.userForm.passNumber !== undefined
                // ) {
                //     if (!/[a-z]/i.test(this.userForm.passNumber[1])) {
                //         //老版通行证
                //         if (!/^[CW]\d{8}$/.test(this.userForm.passNumber)) {
                //             this.$message.error("请输入正确的港澳通行证");
                //             return false;
                //         }
                //     } else {
                //         // 新版通行证
                //         if (
                //             this.userForm.passNumber.indexOf("I") === -1 &&
                //             this.userForm.passNumber.indexOf("O") === -1
                //         ) {
                //             if (
                //                 !/^C[A-Z]\d{7}$/.test(this.userForm.passNumber)
                //             ) {
                //                 this.$message.error("请输入正确的港澳通行证");
                //                 return false;
                //             }
                //         } else {
                //             this.$message.error("请输入正确的港澳通行证");
                //             return false;
                //         }
                //     }
                // }
            }
            this.option = v == 1 ? "save" : "submit";
            return new Promise((resolve, reject) => {
                saveInformation({
                    information: this.userForm,
                    option: this.option,
                }).then((res) => {
                    if (res.code == 200) {
                        if (v == 2) {
                            this.$message.success("提交成功");
                            // 切换步骤保存 下一步的时候才设置
                            this.saveUserData(2);
                            setTimeout(() => {
                                this.$router.replace({
                                    name: "step2",
                                });
                            }, 500);
                        } else {
                            this.dataEcho();
                            this.$message.success("保存成功！");
                            this.isSave = false;
                        }
                        resolve(res);
                    } else {
                        this.$message.error(res.msg);
                        this.isSave = false;
                        reject(res);
                    }
                });
            });
        },
        //保存表单
        saveForm(formData) {
            localStorage.setItem("stepChange", 0);
            this.$store.commit("stepChange");
            if (formData == "save") {
                this.isSave = true;
                return this.addApplyer(1);
            }
            this.$refs[formData].validate((valid) => {
                if (valid) {
                    this.addApplyer(2);
                } else {
                    return false;
                }
            });
        },
        // 更改现居住地址
        changeAddress(v) {
            if (v === 0) {
                this.userForm.address.area = ["", ""];
            } else {
                this.userForm.address.foreign = "";
            }
        },
        // 更改出生城市
        changeBirthPalce(e, item) {
            if (e === 0) {
                item.birthPlace.foreign = "";
            } else {
                item.birthPlace.area = ["", ""];
            }
        },
    },
};
</script>

<style lang="less" scoped>
.card-container {
    width: 960px;
    margin: 0 auto;

    .card-heard {
        height: 35px;
        line-height: 35px;
        background: #409eff;
        padding-left: 16px;
        border-radius: 4px 4px 0px 0px;
        color: #fff;
        margin-bottom: 16px;
    }
}

.operations-group {
    width: 960px;
    margin: 25px auto 50px;
    display: flex;
    justify-content: flex-end;
}

.demo-ruleForm {
    margin-left: 8px;
    // height: 500px;
}

.one-input {
    width: 400px;
    display: inline-block;
}

.doubles {
    margin-left: 60px;
}

.person-btn {
    line-height: 22px;
    font-size: 14px;
    color: #606266;
    padding: 5px 16px;
    border-radius: 2px;
    margin-left: 16px;
    border: 1px solid #dcdfe6;
}

.btn-blue {
    color: #ffffff;
    background: #409eff;
    border: none;
}

.input-width {
    width: 340px;
}

.input-width150 {
    width: 240px;
}

.last-one-input {
    position: relative;
    top: -100px;
    width: 484px;
    margin-left: 459px;
}
</style>
